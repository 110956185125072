import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Badge } from "styled-badge-component";
import "../styles/index.css";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ProjectCard title="Weave" bg="url(fmp/demo2.JPG)" link="/weave" individual={true} className="weave" keywords={["discursive design", "data physicalisation", "immigrants", "cross-culture", "participatory design", "play and politics"]} mdxType="ProjectCard"> Data physicalisation of invisible barriers faced by immigrants navigating Dutch public systems
    </ProjectCard>
    <ProjectCard title="Do you see what i see" bg="url(doyousee/Nexum_day3-10.jpg)" link="/doyousee" mdxType="ProjectCard"></ProjectCard>
    <ProjectCard title="Unbox" bg="url(unbox/full2.jpg)" link="/unbox" className="unbox" mdxType="ProjectCard"></ProjectCard>
    <ProjectCard title="Swarm" bg="url(swarm/swarm1.png)" link="/swarm" className="swarm" mdxType="ProjectCard"></ProjectCard>
    {/* <ProjectCard title="Face Encoded" bg="url(face/face3.jpg)" link="/face">
      A neural network system for face detection and recognition with real-world
      photos
     </ProjectCard>
     */}
    <ProjectCard title="SeaSaw" bg="url(seasaw/seasaw3.png)" link="/seasaw" className="seasaw" keywords={["children", "controlled risk", "knife", "embodied movement", "playfulness"]} textColor="black" mdxType="ProjectCard">
  A playful kitchen experience for children
    </ProjectCard>
    <ProjectCard title="Narrative Tool" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" className="narrative" link="/narrative" bg="url(narrative/cover.jpg)" textColor="black" keywords={["storytelling", "programming", "UX/UI design", "data visualisation"]} mdxType="ProjectCard"></ProjectCard>
    <ProjectCard title="Play and Vulnerability" bg="url(jenga/front.jpg)" individual={true} className="vulnerability" link="/jenga" keywords={["jenga", "experiment design", "first person perspective", "sensory ethnography research"]} mdxType="ProjectCard"></ProjectCard>
    <ProjectCard title="Flow" bg="url(flow/children.jpg)" link="/flow" className="flow" keywords={["design research", "children", "social play", "fantasy play", "playground for introverts"]} mdxType="ProjectCard">
How do space-changing elements influence children’s social interaction in play
    </ProjectCard>
    <ProjectCard title="Ride with Wind" individual={true} bg="url(wind/front.png)" className="wind" link="/wind" keywords={["lego house", "learning through play", "play with nature"]} mdxType="ProjectCard">
  Play with unruly nature
    </ProjectCard>
    <ProjectCard title="Nebula" bg="url(nebula.jpg)" className="nebula" link="/nebula" keywords={["inclusive design", "haptic wayfinding", "wearable technology", "visually impaired"]} mdxType="ProjectCard">
  A haptic navigational belt for the visually impaired
    </ProjectCard>
    {/*  <ProjectCard
     title="Meet"
     bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
     status="Completed"
     keywords={["internet of things", "growing systems"]}
     > An IoT device for your distant loved ones
     </ProjectCard> */}
    {/* <ProjectCard
     title="Kami"
     bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
     status="Completed"
     keywords={["pragmatism philosophy"]}
     > When a booking rental service connects the past, current and future
     </ProjectCard>  */}
    <ProjectCard title="Vinylify" bg="url(vinylify/vinylify.jpeg)" link="/vinylify" className="vinylify" keywords={["design research", "vinyl", "slow technology", "embodied interaction"]} mdxType="ProjectCard"></ProjectCard>
    {/*
     <ProjectCard
      title="Who care for the carers"
      bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
      status="Completed"
      keywords={[
        "healthcare",
        "mental health",
        "ui/ux design",
        "design sprint",
        "dutch design week",
      ]}
     ></ProjectCard> */}
    {/*
     <ProjectCard
      title="Wemagine"
      bg="url(wemagine/background.png)"
      link="/wemagine"
      className="wemagine"
      keywords={[
        "digital art",
        "business & entrepreneurship",
        "dalle2",
        "hypothesis-driven design",
      ]}
     >
      AI-powered office art display
     </ProjectCard>
     */}
    {/*
     <ProjectCard
      title="Face Recognition"
      bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
      status="Completed"
      link="/face"
      keywords={["artificial intelligence", "neural network"]}
     >
      Applied artificial intelligence in facial recognition
     </ProjectCard>
     <ProjectCard
      title="Argonaut"
      bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
      status="Completed"
      link="/argonaut"
      keywords={["micro-frontend architecture", "typescript", "corporate project"]}
     >
      Micro frontend framework with run-time integration
     </ProjectCard>
     */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      